/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Label,
  Segment,
  SegmentGroup,
} from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import TextArea from "../../app/common/form/TextArea";
import * as Yup from "yup";
import {
  addToFinalAssignment,
  listenToAssignmentFromFireStore,
  listenToAssignmentsFromFireStore,
} from "../../app/firestore/firestoreService";
import { useHistory } from "react-router-dom";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { useDispatch, useSelector } from "react-redux";
import { listenToAssignment, listenToAssignments } from "./assignmentActions";
import useFirestoreCollection from "../../app/hooks/useFireStoreCollection";
import {
  FetchMarkingKeys,
  FetchSubmittedAssignment,
  updateSubmittedAssignment,
  updateSubmittedFinalAssignment,
} from "../../app/api/apiservice";
import { useEffect } from "react";
import { useState } from "react";

export default function GradeFinalAssignment({ match }) {
  const dispatch = useDispatch();
  const { studentId, courseId, assignmentId } = match.params;
  const history = useHistory();
  const assignment = useSelector((state) => state.assignment.assignment);
  const submittedAssignment = assignment?.submittedAssignments?.find(
    (assignment) => assignment?.userId === studentId
  );
  //const { assignments } = useSelector((state) => state.assignment);
  const { currentUser } = useSelector((state) => state.auth);
  const [fetchedAssignment, setFetchedAssignment] = useState({});

  //const [mKeys, setMKeys] = useState([]);

  let mKeys = [
    "Short Answer Questions",
    "Verse Memorization",
    "Long Answer Questions",
  ];
  const [mKeysFromApi, setMKeysFromApi] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const markingKeysResult = await FetchMarkingKeys();

      if (markingKeysResult !== null) {
        setMKeysFromApi(markingKeysResult.data.data);
      }

      const result = await FetchSubmittedAssignment(
        courseId,
        assignmentId,
        studentId
      );

      if (
        result.data.data.fileUrl === null ||
        result.data.data.fileUrl === undefined
      ) {
        console.log("Error");
      } else {
        console.log(result.data.data);
        setFetchedAssignment(result.data.data);
      }
    };

    fetchData().catch(console.error);
  }, []);

  console.log(fetchedAssignment);

  console.log(mKeysFromApi);
  //let submittedAssignment = fetchedAssignment;

  console.log(fetchedAssignment?.comments);

  //const mKeys = assignment?.mk;

  // var attendanceGrade = assignment?.attendance?.filter(
  //   (x) => x.id === studentId
  // )[0].score;

  // var assignment1Grade = assignments
  //   ?.filter((x) => x.id === "assignment1")[0]
  //   ?.submittedAssignments?.filter((x) => x.userId === studentId)[0].grade;
  // var assignment2Grade = assignments
  //   ?.filter((x) => x.id === "assignment2")[0]
  //   ?.submittedAssignments?.filter((x) => x.userId === studentId)[0].grade;
  // var assignment3Grade = assignments
  //   ?.filter((x) => x.id === "assignment3")[0]
  //   ?.submittedAssignments?.filter((x) => x.userId === studentId)[0].grade;
  // console.log(currentUser?.displayName);

  // useFirestoreDoc({
  //   query: () =>
  //     listenToAssignmentFromFireStore(
  //       match.params.courseId,
  //       match.params.assignmentId
  //     ),
  //   data: (assignment) => dispatch(listenToAssignment(assignment)),
  //   deps: [dispatch],
  // });

  // useFirestoreCollection({
  //   query: () => listenToAssignmentsFromFireStore(courseId),
  //   data: (assignments) => dispatch(listenToAssignments(assignments)),
  //   deps: [dispatch],
  // });

  function testFunction(values) {
    const mk1 = document.getElementById(1).value;
    const mk2 = document.getElementById(2).value;
    const mk3 = document.getElementById(3).value;

    values.mk1 = mk1;
    values.mk2 = mk2;
    values.mk3 = mk3;

    return values;
  }

  const initialValues =
    fetchedAssignment?.mk1Num !== null ||
    fetchedAssignment?.mk1Num !== undefined
      ? {
          comments: fetchedAssignment?.comments,
          mk1: mKeys[0],
          mk2: mKeys[1],
          mk3: mKeys[2],
          mk1Num: fetchedAssignment?.mk1Num,
          mk2Num: fetchedAssignment?.mk2Num,
          mk3Num: fetchedAssignment?.mk3Num,
        }
      : {
          comments: "",
          mk1: "",
          mk2: "",
          mk3: "",
          mk1Num: 0,
          mk2Num: 0,
          mk3Num: 0,
        };

  console.log(initialValues);

  return (
    <SegmentGroup>
      <Segment>
        <Header content="Grade Final Test" />
        <a
          href={fetchedAssignment?.fileUrl}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <Button
            color="teal"
            content={
              fetchedAssignment?.isFinalTest
                ? "Download Final Test"
                : "Download Submitted Assignment"
            }
          />
        </a>
       {fetchedAssignment?.vmUrl && (
        <a
        href={fetchedAssignment?.vmUrl}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        <Button
          color="teal"
          content="Dowload VM Submission"
        />
      </a>
       )}
      </Segment>
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            mk1: fetchedAssignment?.mk1,
            mk1Num: fetchedAssignment?.mk1Num,
            mk2: fetchedAssignment?.mk2,
            mk2Num: fetchedAssignment?.mk2Num,
            comments: fetchedAssignment?.comments,
            mk3: fetchedAssignment?.mk3,
            mk3Num: fetchedAssignment?.mk3Num,
          }}
          validationSchema={Yup.object({
            comments: Yup.string().required(),
            mk1Num: Yup.number().positive().required().max(36),
            mk2Num: Yup.number().positive().required().max(40),
            mk3Num: Yup.number().positive().required().max(25),
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              values = testFunction(values);
              console.log(values);
              // const totalGrade =
              //   0.4 *
              //     (((assignment1Grade + assignment2Grade + assignment3Grade) *
              //       100) /
              //       60) +
              //   (0.5 *
              //     ((0.6 * values.mk1Num * 100) / 30 +
              //       (0.4 * values.mk2Num * 100) / 20) +
              //     attendanceGrade);
              // const pass = totalGrade >= 70 ? true : false;
              const totalGrade = values.mk1Num + values.mk2Num + values.mk3Num;
              const response = await updateSubmittedFinalAssignment(
                courseId,
                assignmentId,
                values,
                totalGrade,
                studentId
              );
              if (response.data.data === false)
                setErrors({ err: "Error Submitting. Please contact tech" });
              else {
                setSubmitting(false);
                history.push(
                  `/submittedAssignments/${courseId}/${assignmentId}`
                );
              }
            } catch (error) {
              setErrors({ err: error.message });
            }
          }}
        >
          {({ isSubmitting, submitForm, isValid, errors }) => (
            <Form className="ui form">
              <br />
              {mKeys?.length > 0 && (
                <>
                  <Grid>
                    <Grid.Column width={14}>
                      <TextInput name="mk1" id={1} value={mKeys[0]} />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <TextInput name="mk1Num" type="number" />
                    </Grid.Column>
                  </Grid>

                  <Grid>
                    <Grid.Column width={14}>
                      <TextInput name="mk3" id={3} value={mKeys[2]} />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <TextInput name="mk3Num" type="number" />
                    </Grid.Column>
                  </Grid>

                  <Grid>
                    <Grid.Column width={14}>
                      <TextInput name="mk2" id={2} value={mKeys[1]} />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <TextInput name="mk2Num" type="number" />
                    </Grid.Column>
                  </Grid>
                  <TextArea
                    name="comments"
                    placeholder="Enter Comments for Final Test Here"
                    rows={10}
                  />
                </>
              )}

              {errors.err && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.err}
                />
              )}
              <br />
              <Button
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                type="submit"
                fluidsize="large"
                color="teal"
                content="Submit"
                onClick={submitForm}
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </SegmentGroup>
  );
}
