import React from 'react';
import { Button, Container, Header, Icon, Image, Segment } from 'semantic-ui-react';

export default function LandinPage({history}){
    return(
        <Segment inverted textAlign='center' vertical className='mastHead'>
            <Container>
                <Header as='h1' inverted >
                    <Image size='massive' src ='/assets/logo.jpg' style ={{marginBottom: 12}} />
                    Bhaktivedanta Vedic Academy
                </Header>
                <Button onClick={() => history.push('/home')} size='huge' inverted>
                    Get Started
                    <Icon name='right arrow' inverted />
                </Button>
            </Container>
        </Segment>
    )
}